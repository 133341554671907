<!--
 * @Author: 王昌浩
 * @Date: 2020-07-30 11:13:10
 * @LastEditTime: 2020-10-26 17:39:18
 * @LastEditors: Please set LastEditors
 * @Description: 泊位状态监控详情
 * @FilePath: \trunk\src\pages\equipmentState\berthMonitor\berthStateMonitorDetail.vue
-->
<template>
  <div class="page1">
    <!--面包屑-->
    <div class="breadcrumb">
      <el-button
        class="right"
        type="primary"
        style="width: 88px; height: 36px;"
        @click="$router.go(-1)"
        >返回</el-button
      >
    </div>
    <!--主体内容-->
    <div class="content">
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table
          border
          
          :data="tableData"
          style="width: 100%; font-size: 12px"
        >
          <el-table-column label="事件编号" align="center" prop="orderCode">
          </el-table-column>
          <el-table-column
            label="工单设备类型"
            align="center"
            prop="equipType"
            :resizable="false"
          >
          </el-table-column>
          <el-table-column
            :label="$t('searchModule.Berth_number')"
            align="center"
            width="300px"
            :resizable="false"
          >
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="dark"
                :content="scope.row.berthCodes"
                placement="top"
              >
                <span class="berthCode">{{
                  scope.row.berthCodes | showBerthCode
                }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="所属子车场" align="center" prop="parkName">
          </el-table-column>
          <el-table-column
            label="所属父车场"
            align="center"
            prop="parentParkName"
          >
          </el-table-column>
          <el-table-column :label="$t('searchModule.state')" align="center" prop="state">
            <template slot-scope="scope">
              <span v-show="scope.row.state == '派工'">派工</span>
              <el-tooltip
                v-show="scope.row.state == '搁置'"
                class="item"
                effect="dark"
                :content="scope.row.shelveDesc"
                placement="top"
              >
                <span style="color: #d9001b">搁置</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            label="状态变更时间"
            align="center"
            prop="updatedTime"
          >
          </el-table-column>
          <el-table-column label="派工时间" align="center" prop="reportTime">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      tableData: [],
    };
  },
  filters: {
    showBerthCode(a) {
      if (a) {
        return a.length > 30 ? a.substring(0, 30) + "..." : a;
      } else {
        return a;
      }
    },
  },
  // 方法集合
  methods: {
    // 工单列表
    aomEquOrder() {
      let url = "/acb/2.0/aomEquOrder/page";
      this.$axios
        .get(url, {
          data: {
            berthId: this.$route.query.berthId,
            excludeStatus: 3,
            pageNum: 0,
            pageSize: 0,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.tableData = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
  },
  created() {},
  mounted() {
    this.aomEquOrder();
  },
};
</script>
<style lang="stylus" scoped>
// @import url(); 引入公共css类
.breadcrumb
  height 45px
.berthCode
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
</style>
